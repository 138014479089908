/* src/Navbar.css */
.Navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 1rem 5%;
    box-sizing: border-box;
}

.Navbar-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.Navbar-logo {
    height: auto;
    max-height: 40px;
    width: auto;
    margin-right: 1rem;
    margin-top: -7px;
}

.Navbar-title {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
}

.Navbar-auth {
    margin-left: auto;
}

.Navbar-username {
    color: #333;
    font-size: 1rem;
    font-weight: 500;
}

/* Optional: Add hover effect */
/* .Navbar-brand:hover {
    opacity: 0.8;
} */

