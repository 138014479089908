.button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #A11C6A;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
    margin: 0.5rem;
}

.button:hover {
    background-color: #A11C6A;
}

.button:active {
    transform: scale(0.98);
}

.button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    text-align: center;
    flex: 1;
}

.button-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.bubble-container {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    position: relative;
    width: 100%;
}

.lesson-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    padding: 2rem;
    text-align: center;
    flex: 1;
}


.lesson-container p {
    height: 1.5rem; /* Fixed height to reserve space */
    margin: 0 0 1.2rem 0; /* Remove top margin */
    font-size: 1.1rem;
    color: #333;
}
