.footer {
    width: 100%;
    padding: 1rem 5%;
    text-align: center;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
    color: #6c757d;
    font-size: 0.9rem;
    box-sizing: border-box;
}

.footer a {
    margin-top: -2px;
    color: #6c757d;
    text-decoration: none;
    font-size: 0.95rem;
}

.footer a:hover {
    text-decoration: underline;
}
