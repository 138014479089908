.landing-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    background-color: #f5f5f5;
}

.hero-section {
    text-align: left;
    margin: calc(30vh) 5%;
    width: 90%;
}

.hero-section h1 {
    font-size: 3rem;
    color: #A11C6A;
}

.hero-section p {
    font-size: 1.2rem;
    margin: 1rem 0;
    color: #403d3d;
}


.features-section, .pricing-section, .cta-section {
    width: 80%;
    height: 60%;
    margin: 5vh auto;
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.features-section h2, .pricing-section h2, .cta-section h2 {
    font-size: 2rem;
    color: #A11C6A;
    margin-bottom: 1rem;
    text-align: center;
}

.features-section p, .features-section li, .pricing-section p, .cta-section p, .popup p {
    margin: 1.2rem 0;
    font-size: 1.1rem;
    color: #333;
}

.features-section ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin-left: 3vh;
}

.popup {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center align the buttons */
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #A11C6A;
    border-radius: 8px;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.features-content {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    align-items: center; /* Center vertically */
    padding: 3vh;
}

.features-text {
    flex: 1; /* Allow text to take available space */
}

.lesson-gif {
    width: 50%; /* Adjust width as needed */
    border-radius: 8px; /* Rounded edges */
    margin: 2vh;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

/* Media query for responsive design */
@media (max-width: 768px) {
    .features-content {
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: center; /* Center align items */
    }

    .features-text {
        margin-left: 0; /* Remove left margin */
        margin-top: 20px; /* Add some space above the text */
        text-align: center; /* Center text on small screens */
    }

    .lesson-gif {
        width: 80%; /* Adjust width for mobile */
    }
}
